const yup = require('yup');
const { webAndmobileFlagsData } = require('../web-and-mobile');
const { variationSchema } = require('../common');

const perksShop = Object.freeze({
  launchDarklyKey: 'perks-shop',
  yupSchema: yup.boolean().meta({
    description: 'Enabled the perk shop section of the website.',
  }),
  variants: [
    {
      description: 'off',
      value: false,
    },
  ],
});

const removeGuestMobileRequirement = Object.freeze({
  launchDarklyKey: 'remove-guest-mobile-requirement',
  yupSchema: yup.object({}).meta({
    description: 'Allows a set of restaurant ids that remove the requirement for mobile number to order',
  }),
  variants: [
    {
      description: 'off',
      value: {
        restaurantIds: [],
      },
    },
  ],
});

const orderAtTablePromotionsWeb = Object.freeze({
  launchDarklyKey: 'order-at-table-promotions',
  yupSchema: yup
    .array(
      yup.object({
        buttonLink: yup.lazy(value =>
          typeof value === 'string' && value[0] === '/' ? yup.string() : yup.string().url()
        ),
        header: yup.string().required(),
        textWidth: yup
          .string()
          .matches(/^[0-9]+%$/, { message: 'Only numeric characters and percetange are allowed.' }),
        image: yup.string().required(),
        disclaimer: yup.string(),
        description: yup.string(),
        buttonText: yup.string().required(),
        variant: variationSchema,
      })
    )
    .meta({
      description: 'This controls the content of the promo carousel.',
    }),
  variants: [
    {
      description: 'empty',
      value: [],
    },
    {
      // This variant is here to test coverage
      description: 'Staging - AUS - 22.08.2024 ',
      value: [
        {
          buttonLink: '/menu',
          buttonText: 'Order now',
          description: 'PERinaise + 3 ribs + Reg side from $19.95. Excludes SA & NT. T&Cs apply.',
          header: 'Better Your Burger',
          image: 'https://assets.nandos.com.au/apptile_perinaisebundle_v2.png',
          textWidth: '60%',
        },
        {
          buttonLink: '/menu',
          buttonText: 'Learn More',
          description: 'Banga sanga anyone?.',
          header: 'Create your own hack',
          image: 'https://assets.nandos.com.au/apptile_perinaiseofferv4.png',
          textWidth: '60%',
        },
        {
          buttonLink: '/how-to-nandos',
          buttonText: 'Learn more',
          description: 'Get the low down on ordering',
          header: 'How to Nando’s',
          image: '/static/images/welcome-page/promocards_how_to_nandos.png',
          textWidth: '60%',
        },
      ],
    },
  ],
});

const recaptchaV2 = Object.freeze({
  launchDarklyKey: 'recaptcha-v-2',
  yupSchema: yup
    .object({
      'checkout-form': yup
        .boolean()
        .meta({
          description:
            'Enables recaptcha in checkout form, the challenge shall display if recpatcha v3 fails. Only accessible to guests.',
        })
        .required(),
      'profile-form': yup
        .boolean()
        .meta({
          description: 'Enables recaptcha in the profile update form. Only accessible to members.',
        })
        .required(),
      'receipt-form': yup
        .boolean()
        .meta({
          description: 'Enables recaptcha in the add receipt form. Only available to members.',
        })
        .required(),
    })
    .meta({
      description: 'Temporary flag to enable the usage of recaptcha V2 in various areas of the web app. Web Only.',
    }),
  variants: [
    {
      description: 'Off State',
      value: {
        'checkout-form': false,
        'profile-form': false,
        'receipt-form': false,
      },
    },
  ],
});

// NOTE: For type inference/auto-complete to work we freeze obejects and export as so
module.exports = {
  webFlagsData: Object.freeze({
    ...webAndmobileFlagsData,
    [recaptchaV2.launchDarklyKey]: recaptchaV2,
    [orderAtTablePromotionsWeb.launchDarklyKey]: orderAtTablePromotionsWeb,
    [removeGuestMobileRequirement.launchDarklyKey]: removeGuestMobileRequirement,
    [perksShop.launchDarklyKey]: perksShop,
  }),
};
